import { useCallback, useEffect, useMemo, useState } from "react";
import Paragraph from "../../components/atoms/paragraph";
import Image from "../../components/atoms/image";
import Loader from "../../components/atoms/loader";
import Title from "../../components/atoms/title";
import Button from "../../components/atoms/button";
import Top from "../../components/templates/top";
import MessagesReactModal from "../../components/templates/messages-react-modal";
import { store } from "../../redux/store";
import PersonalDataService from "../../services/personalData.service";
import ProcessContracts from "../../services/processContracts.service";
import { useApi } from "../../hooks/useApi";
import { DataINEuseCase } from "../../../application/use-cases/dataINE.usecase";
import { useFollowLater } from "../../hooks/useFollowLater";
import hexagonExclamationThin from "../../assets/icons/hexagon-exclamation-thin.svg";
import ic_xclose from "../../assets/icons/ic_xclose.svg";
import party from "../../assets/icons/party.svg";
import listText from "../../utils/dictionaries/dictionary-congratulation";
import { trackAnalyticsEvent } from "../../utils/amplitude";
import { AmplitudEventName } from "../../utils/amplitude/types";

const getDataINEService = new PersonalDataService();
const usecasegetDataINE = new DataINEuseCase(getDataINEService);

const Congratulation = () => {
  const state = store.getState();
  const [waitProcess, setWaitProcess] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [clientId, setClientId] = useState(null);
  const { onClickContinue } = useFollowLater();

  const { getFinishProcess } = new ProcessContracts();
  const {
    data: isDataFinishProcess,
    error: isErrorFinishProcess,
    execute: execFinishProcess,
    isLoading: isLoadingFinishProcess,
  } = useApi<string, { processId: string }>(getFinishProcess);

  const mainText = useMemo(() => state.client.clientPersist.bpId === null ? listText.text_3.mainText : listText.text_6.mainText, [state.client.clientPersist.bpId]);
  const buttonText = useMemo(() => state.client.clientPersist.bpId === null ? listText.text_5.mainText : listText.text_7.mainText, [state.client.clientPersist.bpId]);
  const extraParagraph = useMemo(() => state.client.clientPersist.bpId === null ? listText.text_4.mainText : "", [state.client.clientPersist.bpId]);

  const getPersonData = useCallback(async () => {
    const response = await usecasegetDataINE.getDataINE(state.client.clientPersist.uuid);
    if (response.error) {
      setModalShow(true);
    } else {
      setClientId(response.data.clientId);
      setWaitProcess(false);
    }
  }, [state.client.clientPersist.uuid]);

  useEffect(() => {
    getPersonData();
  }, [getPersonData]);

  const endN4 = useCallback(async () => {
    execFinishProcess({ processId: state.client.clientPersist.uuid });
    onClickContinue({
      data: {
        dataPersist: state.client.dataPersist,
      },
      processId: state.client.clientPersist.uuid,
      resource: "Congratulation",
      step: "CONTINUE",
    });
    trackAnalyticsEvent(
      AmplitudEventName.Terminar_proceso_N4,
      { "Forma_de_ingreso": state.client.clientPersist.bpId ? "Onboarding" : "Banner" }
    );
  }, [execFinishProcess, onClickContinue, state.client]);

  useEffect(() => {
    if (isDataFinishProcess === "The process has finished successfully" && !isErrorFinishProcess && !isLoadingFinishProcess) {
      localStorage.clear();
      window.location.reload();
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "ENROLLED_USER_N4",
            data: {
              curp: state.client.confirmData.curp,
              clientId: clientId,
              cellphone: state.client.dataPersist.phone,
            },
          })
        );
        localStorage.removeItem("persist:root");
      }
    } else if (!isDataFinishProcess && isErrorFinishProcess && !isLoadingFinishProcess) {
      setModalShow(true);
    }
  }, [isDataFinishProcess, isErrorFinishProcess, isLoadingFinishProcess, clientId, state.client]);

  
  return (
    <>
      {waitProcess || isLoadingFinishProcess ? (
        <Loader />
      ) : (
        <div className="content-container">
          <Top
            titleHeader={listText.text_1.mainText}
            displayProgressBar="d-none"
            classHeader="mt-2"
            comeFrom="/Congratulation" subTitle={""} displayLeftImageTitle={""} classTitleImage={""} styleHeader={undefined} classProgressBar={""} styleProgressBar={undefined} classTitleAndImage={""} styleTitleAndImage={undefined} valueProgressBar={""} onClickGoBackIcon={undefined} />
          <div className="p-3">
            <div className="text-center my-5">
              <Image srcImage={party} className={""} alt={""} dataBsDismiss={undefined} styleImg={undefined} onClick={undefined} />
            </div>
            <div className="text-center mb-3">
              <Title
                text={listText.text_2.mainText}
                styleTitle={{
                  fontWeight: "700",
                  fontSize: "28px",
                  fontFamily: "Roboto",
                  color: "#00254A",
                }} className={""} />
            </div>
            <div className="text-center mb-3">
              <Paragraph
                text={mainText}
                styleParagraph={{
                  fontWeight: "400",
                  fontSize: "20px",
                  fontFamily: "Roboto",
                  color: "#00254A",
                }} className={""} />
            </div>
            <div>
              <Paragraph
                text={extraParagraph}
                styleParagraph={{
                  fontWeight: "400",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  color: "#00254A",
                }} className={""} />
            </div>
          </div>
          <div className="footer--pin">
            <Button
              text={buttonText}
              divClassName="d-grid gap-2"
              buttonClassName="button-enabled w-100 place-content-center align-self-center"
              spinner={isLoadingFinishProcess}
              onClick={endN4}
            />
          </div>
        </div>
      )}
      <MessagesReactModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        textTitle="¡Ups lo sentimos!"
        classTitle="fw-bold"
        styleTitle={{ color: "#00254A", fontSize: "20px", fontWeight: "700" }}
        listButtonsModal={[
          {
            text: "Reintentar",
            buttonClass: "button-enabled w-100 place-content-center align-self-center mt-3",
            onClick: () => setModalShow(false),
          },
        ]}
        imageModal={hexagonExclamationThin}
        onClickClose={() => setModalShow(false)}
        imageOnlyClose={ic_xclose}
        classContent="ml-3 mr-3 mb-3 text-center"
        classImageModal="text-center my-4"
        listParagraphs={[
          {
            textParagraph: "La aplicación no esta respondiendo debido a que hay problemas con la comunicación de nuestros sistemas.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
          {
            textParagraph: "Vuelve a intentar si el error persiste.",
            classParagraph: "mt-2 mx-2",
            styleParagraph: { fontSize: 16, color: "#00254A", fontWeight: "450" },
          },
        ]}
      />
    </>
  );
};

export default Congratulation;